(function(){

	var botaoCliqueTopo = $('#irtopo');

	botaoCliqueTopo.click(function(){

		$('body,html').animate({
			scrollTop:0
		},1200)

	});

	$(window).scroll(function(){

		if( $(this).scrollTop() >= 275){
			botaoCliqueTopo.addClass('voltou');
		}else{
			botaoCliqueTopo.removeClass('voltou');
		}

	});

})();