(function(){

	var gatilhoMenu = $('.bg-menu, #toggleMenu');

	gatilhoMenu.click(function(){

		var alvoMenuToggle = $('#topo');

		alvoMenuToggle.toggleClass('ativo');

	});

})();