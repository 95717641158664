function joazin(id, opcoes){
    if($(id).children().length > 1){
        $(id).owlCarousel(opcoes)
    }else{
        $(id).addClass('owl-loaded');
    }
}

joazin('#carousel-produtos',{
    loop:true,
    nav:true,
    dots:false,
    navText: [
        '<span class="pull-left icon icon-slide-produtos-left"></span>',
        '<span class="pull-right icon icon-slide-produtos-right"></span>'
    ],
    autoplay:true,
    responsive:{
        0:{
            items:1,
        },
        600:{
            items:1,
        },
        1170:{
            items:1,
        }
    }
})