(function(){

	var formSerFocado = $('#form-busca');

	var formControlFocado = formSerFocado.find('.form-control');

	var toggleClassFocado = formSerFocado.find('.input-group');

	formControlFocado.focus(function(){

		toggleClassFocado.addClass('focado');

	});

	formControlFocado.blur(function(){

		toggleClassFocado.removeClass('focado');

	});

})();