(function(){

	var formGroupCustom = $('.form-group-custom');

	var botaoFake = $(formGroupCustom).find('.botao-fake');

	botaoFake.click(function(){

		var inputSofridoEventoFake = $(formGroupCustom).find('input');

		inputSofridoEventoFake.click();

	});

})();