(function(){

	var vigiaIstoPorfavor = $('#bar-menu');

	function joao(){

		var pegarHeightParaJaoVerificar = document.querySelector('#bar-menu').getBoundingClientRect().height;

		if( pegarHeightParaJaoVerificar >= 90 ){

			vigiaIstoPorfavor.addClass('aumentar-20');

		}else{

			vigiaIstoPorfavor.removeClass('aumentar-20');

		}

	};

	joao();


	$(window).resize(function(){
		joao();
	});

})();