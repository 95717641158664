(function(){

	var contatoLegal = $('.contato-legal');

	var linkHoverLegal = contatoLegal.find('a');

	linkHoverLegal.hover(function(){

		$(this).closest('.contato-legal').addClass('ativo');

	});

	linkHoverLegal.mouseleave(function(){

		$(this).closest('.contato-legal').removeClass('ativo');

	});

})();