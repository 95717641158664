(function(){

	var alvoClasseFixa = $('#topo');

	$(window).scroll(function(){

		var alturaTopoParaVigiar = document.querySelector('#topo').getBoundingClientRect();

		if( $(this).scrollTop() >  alturaTopoParaVigiar.height){
			alvoClasseFixa.addClass('menu-fixo');
		}else{
			alvoClasseFixa.removeClass('menu-fixo');
		}

	});

})();